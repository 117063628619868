import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

import React, { Component, useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Select, { components } from "react-select"

import Layout from "../components/layout"
import Breadcrumb from "../components/breadcrumb"

const List = ({ data, pageContext }) => {
  const allRoles = data.allContentfulRole.edges
  const categories = data.allContentfulBusinessFunction.edges

  const [roles, setRoles] = useState(allRoles)
  const [selected, setSelected] = useState("all")

  const options = []

  options.push({
    label: "All Business Units",
    subLabel: allRoles.length,
    value: "all",
  })

  categories.map(function(category) {
    if (
      allRoles.filter(
        role => role.node.businessFunction.title === category.node.title
      ).length
    ) {
      options.push({
        label: category.node.title,
        subLabel: allRoles.filter(
          role => role.node.businessFunction.title === category.node.title
        ).length,
        value: category.node.title,
      })
    }
  })

  const ValueOptionLabel = props => {
    return (
      <components.ValueContainer {...props}>
        <div className="business-function__select-selected">
          {props.data.label} ({props.data.subLabel})
        </div>
      </components.ValueContainer>
    )
  }

  const Option = props => {
    return (
      <components.Option {...props}>
        <div className="business-function__select-item">
          <div className="business-function__select-label">
            {props.data.label}
          </div>
          <div className="business-function__select-count">
            {props.data.subLabel}
          </div>
        </div>
      </components.Option>
    )
  }

  const filterRoles = item => {
    let selectedRoles = allRoles
    if (item.value !== "all") {
      selectedRoles = allRoles.filter(
        role => role.node.businessFunction.title === item.value
      )
    }

    setSelected(item.value)
    setRoles(selectedRoles)

    // store in cache
    if (typeof window !== "undefined" && item.value !== null) {
      sessionStorage.setItem("businessFunction", item.value)
    }
  }

  const truncate = (input, length) => {
    if (input.length > length) return input.substring(0, length) + "..."
    else return input
  }

  if (typeof window !== "undefined") {
    useEffect(() => {
      if (sessionStorage.getItem("businessFunction") !== null) {
        filterRoles({ value: sessionStorage.getItem("businessFunction") })
      }
    }, [sessionStorage.getItem("businessFunction")])
  }

  return (
    <Layout>
      <Breadcrumb current="1" />

      <div className="roles">
        <div className="business-function">
          <div className="container">
            <div className="heading-wrapper">
              <h1>Choose your Desired Role</h1>
            </div>
            <div className="select-wrapper">
              <p>Choose a Business Unit</p>
              <Select
                name="business-function__select"
                isSearchable={false}
                options={options}
                components={{ Option: Option, SingleValue: ValueOptionLabel }}
                onChange={filterRoles}
                defaultValue={
                  typeof window !== "undefined" &&
                  sessionStorage.getItem("businessFunction") !== null
                    ? options.find(
                        item =>
                          item.value ===
                          sessionStorage.getItem("businessFunction")
                      )
                    : options[0]
                }
                classNamePrefix="business-function__select"
              />
            </div>
          </div>
        </div>

        <div className="role-list container">
          {roles.map(role => (
            <div className="role-list__item" key={role.node.id}>
              <Link
                to={`/${role.node.businessFunction.slug}/${role.node.slug}/role/`}
              >
                <p className="role-list__item-subtitle">
                  {role.node.businessFunction.title}
                </p>
                <h2 className="role-list__item-title">{role.node.title}</h2>
                <p className="role-list__item-description">
                  {role.node.description.description}
                </p>
                <h4 className="role-list__item-listheading">
                  FOR THE INDIVIDUAL THAT
                </h4>
                <ul>
                  {role.node.forTheIndividualThatEnjoys !== null && role.node.forTheIndividualThatEnjoys.map((item, index) => (
                    <li key={role.node.id + "-" + item + index}>{item}</li>
                  ))}
                </ul>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default List

export const pageQuery = graphql`
  query ListQuery {
    allContentfulRole(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          slug
          businessFunction {
            title
            slug
          }
          description {
            description
          }
          topCompetencies {
            proficiency {
              title
              value
            }
            competency {
              title
            }
          }
          forTheIndividualThatEnjoys
        }
      }
    }
    allContentfulBusinessFunction(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          slug
          title
        }
      }
    }
  }
`
